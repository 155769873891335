import { Controller } from '@hotwired/stimulus'

import { minimalSetup } from 'codemirror'
import { Compartment, EditorState } from '@codemirror/state'
import { EditorView, placeholder } from '@codemirror/view'
import { StreamLanguage } from '@codemirror/language'
import { ruby } from '@codemirror/legacy-modes/mode/ruby'
import { cobalt } from 'thememirror'

export default class extends Controller {
  #editorTheme = new Compartment()
  #editorView = undefined

  connect () {
    const textarea = this.element

    this.#editorView = new EditorView({
      doc: textarea.value,
      extensions: [
        minimalSetup,
        StreamLanguage.define(ruby),
        EditorView.editorAttributes.of({
          class:
            'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-1 py-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500'
        }),
        EditorView.updateListener.of((update) => {
          textarea.value = update.state.doc.text.join('\n')
        }),
        EditorState.readOnly.of(textarea.disabled),
        this.#editorTheme.of(this.#theme),
        placeholder(textarea.placeholder)
      ]
    })
    this.element.parentNode.insertBefore(this.#editorView.dom, this.element)
    this.element.style.display = 'none'

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener(
      'change',
      (event) => {
        const newColorScheme = event.matches ? 'dark' : 'light'
        this.#switchThemes(newColorScheme)
      }
    )
  }

  disconnect () {
    this.#editorView.destroy()
  }

  get #theme () {
    const isDarkMode = window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches

    return isDarkMode ? cobalt : []
  }

  #switchThemes () {
    this.#editorView.dispatch({
      effects: this.#editorTheme.reconfigure(this.#theme)
    })
  }
}
